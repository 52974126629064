import { Toast, Row, Col, Icon, Cell, CellGroup, Checkbox, CheckboxGroup, IndexBar, IndexAnchor, Popup, Sticky, Dialog, Overlay, Collapse, CollapseItem } from 'vant';
import { ref, getCurrentInstance, reactive } from "vue";
import axios from "axios";
import qs from "qs";
import { jsonp } from "vue-jsonp";
import md5 from 'js-md5';
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    [Sticky.name]: Sticky,
    [Dialog.name]: Dialog,
    [Overlay.name]: Overlay,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },

  data() {
    return {};
  },

  setup() {
    const activeName = ref(''); // themeVars 内的值会被转换成对应 CSS 变量
    // 比如 sliderBarHeight 会转换成 `--van-slider-bar-height`

    const themeVars = reactive({
      CellBackgroundColor: '#CE853D',
      CellRightIconColor: '#FFFFFF',
      CellTextColor: '#FFFFFF',
      CollapseItemContentBackgroundColor: '#D79D32',
      CollapseItemContentTextColor: '#FFFFFF',
      BorderColor: '#F2DF12',
      CellBorderColor: '#F2DF12',
      CellHorizontalPadding: '0rem',
      PaddingMd: '0rem',
      PaddingSm: '0rem',
      CellActiveColor: '#D79D32'
    });
    return {
      activeName,
      themeVars
    };
  },

  methods: {},
  beforeMount: function () {
    /*
    jsonp("https://m.dcdapp.com/motor/activity/apibus/open/get_ip_city/").then((res) => {
        res = res.data;
        //请求成功
        if(typeof(res.city_name) != 'undefined'){
            this.form.city = res.city_name;
        }else{
            this.form.city = "北京"
        }
    })
    .catch( err => {
        this.form.city = "北京"
        console.log(err)
    })
    */
  }
};